.custom-alertmodal .mat-dialog-container {
    display: block;
    padding: 24px;
    border-radius: 30px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
}

.mat-tab-body-content {
    overflow-x: hidden !important;
}